import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { gauth } from 'images/koppelingen'
import { employes } from 'images'
import Layout from '../../../../layouts'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'

const dummyKlantVerhaal = {
  pageTitle: '“Makkelijk contact en duidelijkheid voor iedereen”',
  pageStreamer: 'Marieke de Vos, Codaisseur',
  useCase: 'Founders',
  person: {
    name: 'Marieke',
    functionTitle: 'HR MANAGER, ebay',
    companyLogo: 'https://via.placeholder.com/129x23',
    description: 'Learn modern web development and Hire the best trained developers. Cras curabitur amet pulvinar.',
    portraitImage: 'https://via.placeholder.com/348x206',
    portraitImageSmall: 'https://via.placeholder.com/344x224/ff48df',
  },
  content: [{
    title: 'Over Google Authenticator',
    description: 'Met Google Authenticator voeg je een extra beveiligingslaag voor je Employes-account toe. In plaats van dat je alleen een wachtwoord invoert bij het inloggen, geef je ook een code op die je met de mobiele app genereert. Via deze extra stap kan je ervoor zorgen dat alleen jij toegang hebt tot je account.'
    }, {
      title: 'Hoe werkt de koppeling tussen Google Authenticator en Employes? ',
      description: 'Het instellen van de koppeling tussen Google Authenticator kun je eenvoudig doen als je bent ingelogd in Employes. In het volgende supportartikel staat hoe je de koppeling instelt. Het instellen hiervan is beschikbaar voor alle verschillende soorten gebruikers binnen Employes. Bijvoorbeeld voor je eigen werkgeversaccount maar ook voor je werknemers of accountant.'
    }, {
      image: gauth
    }, {
      title: 'Extra veilig met tweestapsverificatie',
      description: 'Wanneer je inlogt met 2FA, zul je een extra code moeten invoeren. Hiermee zijn je gegevens extra goed beveiligt!'
  }]
}

class GoogleAuth extends Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <main className="koppeling single">

          <Helmet>
            <title>Koppeling Google Authenticator & Employes | Salarisadministratie</title>
            <meta name="description" content="Gebruik tweestapsverificatie als extra beveiliging van je gegevens in Employes. Koppel nu Google Authenticator of soortgelijk."/>
            <meta itemprop="name" content="Koppeling Google Authenticator & Employes | Salarisadministratie" />
            <meta itemprop="description" content="Gebruik tweestapsverificatie als extra beveiliging van je gegevens in Employes. Koppel nu Google Authenticator of soortgelijk."/>
            <meta itemprop="image" content="/static/meta-img.png" />
          </Helmet>

          <header className="koppeling gauth padding-xxl">
            <div className="container-sm">
              <div className="grid yg align-middle">
                <div className="col-6">
                  <h1>Koppel Google Authenticator aan <span className="hide-mobile">je salarisadministratie in</span> Employes.</h1>
                </div>

                <div className="col-6">
                  <div className="koppeling-image">
                    <img className="margin-s-bottom" src={employes} alt="logo employes"/>
                      <svg className="margin-s-bottom" xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
                      <path fill="#474750" fillRule="evenodd" d="M167,130 L198,130 L198,131 L167,131 L167,163 L165,163 L165,131 L134,131 L134,130 L165,130 L165,99 L167,99 L167,130 Z" opacity=".4" transform="translate(-134 -99)"/>
                    </svg>

                    <img src={gauth} className="google-auth-logo" alt="gauth afbeelding"/>

                  </div>
                </div>
              </div>
            </div>
          </header>

          <section className="section-content padding-xl-top">

          <div>
            <div className="section margin-m-bottom">
              <div className="container-sm">
                <div className="grid yg center">
                  <div className="col-12 padding-m">
                      <div className="text">
                        <h4>Over Google Authenticator</h4>
                        <p>Met Google Authenticator voeg je een extra beveiligingslaag toe aan je Employes-account. In plaats van dat je alleen een wachtwoord invoert bij het inloggen, geef je ook een code op die je met de mobiele app genereert. Via deze extra stap kan je ervoor zorgen dat alleen jij toegang hebt tot je account.</p>
                      </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="section margin-m-bottom">
              <div className="container-sm">
                <div className="grid yg center">
                  <div className="col-12 padding-m">
                      <div className="text">
                        <h4>Hoe werkt de koppeling tussen Google Authenticator en Employes?</h4>
                        <p>Het instellen van de koppeling tussen Google Authenticator kun je eenvoudig doen als je bent ingelogd in Employes. In het volgende <a href="https://support.employes.nl/nl/articles/5373629-instellen-van-tweestapsverificatie-2fa" target="_blank" rel="nofollow">supportartikel</a> staat hoe je de koppeling instelt.</p>
                        <p>Het instellen hiervan is beschikbaar voor alle verschillende soorten gebruikers binnen Employes. Bijvoorbeeld voor je eigen werkgeversaccount maar ook voor je werknemers of accountant.</p>
                        <p>Tijdens het instellen van tweestapsverificatie is het belangrijk dat je de backup codes download en goed opslaat. Anders verlies je mogelijk permanent toegang tot je salarisadministratie.</p>
                      </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="section margin-m-bottom">
              <div className="container-sm">
                <div className="grid yg center">
                  <div className="col-12 padding-m">
                      <div className="text">
                        <h4>Extra veilig met tweestapsverificatie</h4>
                        <p>Wanneer je inlogt met 2FA, zul je een extra code moeten invoeren. Hiermee zijn je gegevens extra goed beveiligt!</p>
                        <p>Het is overigens niet noodzakelijk dat je specifiek <a href="https://support.google.com/accounts/answer/185839?hl=nl&ref_topic=2954345" target="_blank" rel="nofollow">Google Authenticator</a> gebruikt voor het instellen van tweestapsverificatie. Employes ondersteunt ook het gebruik van andere 2FA-app's zoals die van <a href="https://authy.com/download/" target="_blank" rel="nofollow">Authy</a>. Dus je kunt zelf de app kiezen die jij het handigst vindt.</p>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          </section>

          <div className="section margin-m-bottom">
            <div className="container-sm">
              <div className="grid yg center">
                <div className="col-12">
                  <Link to="/product/koppelingen/" className="link blue">Terug naar koppelingen</Link>
                </div>
              </div>
            </div>
          </div>

        </main>
      </Layout>
    )
  }
}

GoogleAuth.propTypes = {
  data: PropTypes.object
}

export default GoogleAuth
